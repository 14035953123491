<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span>조회시간: {{ str_time }}</span>
        <button type="button" class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <h5 class="stock_title">재고 현황</h5>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>제품명</th>
              <th>창고명</th>
              <th>전재고</th>
              <th>현재고</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filterProductList" :key="index">
              <td>{{ item.name + ' - ' + item.standard }}</td>
              <td>{{ item.storeLocation }}</td>
              <td>{{ item.beforeStock_unit }}</td>
              <td>{{ item.nowStock_unit }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct', // 일단 그냥 확인
      productTotalStock: 'getProductTotalStock',
      stockStatusList: 'getProductStockStatusListFromProductStockStatus',
      productInOutType: 'getProductInOutType',
      productControlList: 'getProductControl',
      unitCodes: 'getUnitCodes',
      boxs: 'getBoxs', // 현재고 출고박스 기준  box에서 box_unit_id로 확인, null 이면 낱개
      stores: 'getStores',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filterProductList() {
      let productsList = [];

      if (
        this.productTotalStock.length > 0 &&
        this.productInOutType.length > 0
      ) {
        let productTotalStockClone = this.lodash
          .clonedeep(this.productTotalStock)
          .filter(x => x.stock_quantity > 0)
          .sort((a, b) => a.product_id - b.product_id);

        productTotalStockClone.forEach(el => {
          let productInfo = this.findInfoFromId(this.products, el.product_id);
          let foundLastProductControl = this.filterStoreLocation.filter(
            x => x.product_id == el.product_id,
          );
          const lastStoreId =
            foundLastProductControl.length > 0
              ? foundLastProductControl[0].store_id
              : null;

          let beforeStock = el.stock_quantity;
          let inStockSum = 0;
          let outStockSum = 0;

          if (this.filterStockStatusList[0].length > 0) {
            inStockSum = this.lodash
              .clonedeep(this.filterStockStatusList[0])
              .filter(
                x =>
                  x.product_id == el.product_id &&
                  (x.product_in_out_type_id ==
                    this.productInOutType.find(y => y.detail == 'process in')
                      .id ||
                    x.product_in_out_type_id ==
                      this.productInOutType.find(y => y.detail == 'return in')
                        .id),
              )
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            outStockSum = this.filterStockStatusList[0]
              .filter(
                x =>
                  x.product_id == el.product_id &&
                  (x.product_in_out_type_id ==
                    this.productInOutType.find(y => y.detail == 'sales out')
                      .id ||
                    x.product_in_out_type_id ==
                      this.productInOutType.find(y => y.detail == 'using out')
                        .id),
              )
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            beforeStock = this.$decimalSub(
              this.$decimalAdd(el.stock_quantity, outStockSum),
              inStockSum,
            );
          }

          productsList.push({
            name: productInfo.name,
            standard: productInfo.standard,
            beforeStock_unit:
              this.$makeComma(beforeStock) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.stock_unit_id)
                .name +
              ')',
            beforeStock_box:
              productInfo.box_unit_id == null
                ? this.$makeComma(beforeStock)
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(
                        beforeStock,
                        this.findInfoFromId(this.boxs, productInfo.box_unit_id)
                          .quantity,
                      ),
                    ),
                  ),
            inStock:
              this.$makeComma(inStockSum) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.stock_unit_id)
                .name +
              ')',
            outStock:
              this.$makeComma(outStockSum) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.delivery_unit_id)
                .name +
              ')',
            nowStock_unit:
              this.$makeComma(el.stock_quantity) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.stock_unit_id)
                .name +
              ')',
            nowStock_box:
              productInfo.box_unit_id == null
                ? this.$makeComma(el.stock_quantity)
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(
                        el.stock_quantity,
                        this.findInfoFromId(this.boxs, productInfo.box_unit_id)
                          .quantity,
                      ),
                    ),
                  ),
            storeLocation:
              lastStoreId == null
                ? '-'
                : this.findInfoFromId(this.stores, lastStoreId).name,
          });
        });
      }
      // let arr = [];
      // if (this.fullScreen) {
      //   productsList.forEach((element, index) => {
      //     if (index % 9 == 0) {
      //       arr.push(productsList.slice(index, index + 9));
      //     }
      //   });
      // } else {
      //   productsList.forEach((element, index) => {
      //     if (index % 5 == 0) {
      //       arr.push(productsList.slice(index, index + 5));
      //     }
      //   });
      // }
      // return arr;
      return productsList;
    },
    filterStockStatusList() {
      let stockStatusClone = this.lodash.clonedeep(this.stockStatusList);

      if (this.stockStatusList.length > 0) {
        let today = new Date();
        today = yyyymmdd(today);

        const todayList = this.lodash
          .clonedeep(stockStatusClone)
          .filter(x => x.create_time.substr(0, 10) == today);

        const beforeList = stockStatusClone.filter(
          x => x.create_time.substr(0, 10) != today,
        );

        return [todayList, beforeList];
      } else {
        return [[], []];
      }
    },
    filterStoreLocation() {
      let storeLoctionClone = this.lodash.clonedeep(this.productControlList);

      if (this.productControlList.length > 0) {
        storeLoctionClone = storeLoctionClone.sort(
          (b, a) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        );
      }
      return storeLoctionClone;
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_CONTROL', '재고 관리');
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_STATUS', '제품 현황');
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  async created() {
    if (this.stores.length < 1) {
      await this.FETCH('FETCH_STORE', '창고');
    }
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');

    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    if (this.productInOutType.length < 1) {
      await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '제품 입출고 유형');
    }

    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
    await this.FETCH('FETCH_PRODUCT_STATUS', '제품 현황');
    await this.FETCH('FETCH_PRODUCT_CONTROL', '재고 관리');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
        await this.FETCH_NO_SPINNER('FETCH_PRODUCT_STATUS', '제품 현황');
        await this.FETCH_NO_SPINNER('FETCH_PRODUCT_CONTROL', '재고 관리');
      }

      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    // if (this.swiper != undefined) {
    //   this.swiper.slideTo(1, 0, false);
    // }
  },
};
</script>

<style lang="scss" scoped></style>
