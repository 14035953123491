<template>
  <div
    id="contents"
    class="status_board_saerom"
    :class="{
      weekly_production_target: tabIndex == 0,
      plc_th_status: tabIndex == 0,
      finished_order: tabIndex != 0,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="[
            { title: '주간 생산 목표', name: 'weekly_production_target' },
            { title: '완제품 재고 현황', name: 'finished_stock_order' },
            { title: '완제품 출고 지시서', name: 'finished_release_order' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">주간 생산 목표</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">완제품 재고 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">완제품 출고 지시서</a>
          </li>
        </ul>
      </div>
      <div
        class="swiper-container"
        v-if="tabIndex == 0"
        :class="{ active: slideGo }"
      >
        <div class="swiper-wrapper">
          <weekly-production-target-form
            @fullScreen="slideGo = $event"
            class="swiper-slide"
            v-if="tabIndex == 0"
            :fullBtn="fullBtn"
          ></weekly-production-target-form>
          <temp-real-time-form
            @fullScreen="slideGo = $event"
            class="swiper-slide"
            v-if="tabIndex == 0 && slideGo"
          ></temp-real-time-form>
        </div>
      </div>
      <finished-stock-order-form
        v-if="tabIndex == 1"
      ></finished-stock-order-form>
      <finished-release-order-form
        v-if="tabIndex == 2"
      ></finished-release-order-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import WeeklyProductionTargetForm from '@/views/forms/Custom/Monitor/9/WeeklyProductionTargetForm';
import TempRealTimeForm from '@/views/forms/Custom/Monitor/9/TempRealTimeForm';
import FinishedStockOrderForm from '@/views/forms/Custom/Monitor/9/FinishedStockOrderForm';
import FinishedReleaseOrderForm from '@/views/forms/Custom/Monitor/9/FinishedReleaseOrderForm';
import FavoriteMixin from '@/mixins/favorite';
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use(Autoplay);

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    WeeklyProductionTargetForm,
    TempRealTimeForm,
    FinishedStockOrderForm,
    FinishedReleaseOrderForm,
  },
  data() {
    return {
      swiper: null,
      slideGo: false,
      fullBtn: true,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
  },
  mounted() {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      freeMode: true,
      resizeReInit: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 20000,
        disableOnInteraction: false,
      },
    });

    this.swiper.autoplay.stop();
    this.swiper.allowTouchMove = false;
  },
  updated() {
    this.$nextTick(() => {
      if (this.slideGo && this.tabIndex == 0) {
        this.swiper.autoplay.start();
        this.swiper.allowTouchMove = true;
      } else {
        this.swiper.autoplay.stop();
        this.swiper.allowTouchMove = false;
      }
    });
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'saerom_monitoring';
</style>
