<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span>조회시간: {{ str_time }}</span>
          <button
            type="button"
            class="btn_sub1 refresh_btn"
            @click="refreshData"
          >
            재조회
          </button>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? '닫기' : '전체화면' }}
          </button>
        </div>
        <div>
          <h5>출고 지시서</h5>
          <div class="input_checkbox">
            <label for="checkbox2" class="label_txt">미출고 항목만 표시</label>
            <label
              for="checkbox2"
              class="chk_box"
              :class="{ active: onlyNotOutput }"
              @click.prevent="toggleCheckBox()"
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox2" />
          </div>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>업체명</th>
              <th>품목</th>
              <th>수량</th>
              <th>출고여부</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredRequest" :key="item.id">
              <td>
                {{
                  findInfoFromId(
                    companys,
                    findInfoFromId(sales, item.sales_id).company_id,
                  ).name
                }}
              </td>
              <td>
                {{
                  findInfoFromId(products, item.product_id).name +
                    ' - ' +
                    findInfoFromId(products, item.product_id).standard
                }}
              </td>
              <td>
                <b>{{ $makeComma(item.quantity) }}</b>
                {{
                  item.box_unit_id != null
                    ? findInfoFromId(boxs, item.box_unit_id).name
                    : findInfoFromId(
                        units,
                        findInfoFromId(products, item.product_id).stock_unit_id,
                      ).name
                }}
              </td>
              <td>{{ item.output_yn ? 'Y' : 'N' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import fetchMixin from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      onlyNotOutput: false,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      sales: 'getSalesFromOutputRequest',
      boxs: 'getBoxs',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredRequest() {
      if (this.sales != undefined) {
        let salesClone = this.lodash.clonedeep(this.sales);
        try {
          let filterClone = salesClone
            .map(x => x.product_list)
            .reduce((a, b) => a.concat(b))
            .sort((a, b) => b.id - a.id)
            .filter(x => x.request_out)
            .filter(x => !this.onlyNotOutput || !x.output_yn);

          return filterClone;
        } catch (error) {
          return [];
        }
      } else {
        return '';
      }
    },
  },
  methods: {
    toggleCheckBox() {
      console.log('toggle');
      this.onlyNotOutput = !this.onlyNotOutput;
    },
    async refreshData() {
      const date = new Date();
      const today = yyyymmdd(date);
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_SALES_TO_OUTPUT_REQUEST_TODAY',
        today,
        '출고지시',
      );
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  async created() {
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    const date = new Date();
    const today = yyyymmdd(date);

    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_SALES_TO_OUTPUT_REQUEST_TODAY',
      today,
      '출고지시',
    );
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 1) {
        await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
          'FETCH_SALES_TO_OUTPUT_REQUEST_TODAY',
          today,
          '출고지시',
        );
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
